// third-party
import { createSlice } from '@reduxjs/toolkit';
// import { FormikValues } from 'formik';

// project imports
// import axios from 'utils/axios';
import axios from 'axios';
import { dispatch } from '../index';
import { API_HOST } from 'config';

// types
import { DefaultRootStateProps } from 'types';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['photo'] = {
    error: null,
    thumbnails: [],
    photos: [],
    loading: false,
};

const slice = createSlice({
  name: 'photo',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET Thumbnail
    getThumbnailSuccess(state, action) {
      state.thumbnails = action.payload;
    },

    // GET Photo
    getPhotoSuccess(state, action) {
      state.photos = action.payload;
    },

    // SET LOADING
    setLoading(state, action) {
      state.loading = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function uploadPhotos (eventId: number, photos: any[]) {
  return async () => {
    try {
      dispatch(slice.actions.setLoading(true));
      await axios.post(`${API_HOST}/photo/${eventId}`, photos);
      dispatch(slice.actions.setLoading(false));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setLoading(false));
    }
  };
}

// export function getThumbnails (eventId: number) {
//   return async () => {
//     try {
//       dispatch(slice.actions.setLoading(true));
//       const response = await axios.get(`${API_HOST}/photo/${eventId}?thumbnail=true`);
//       dispatch(slice.actions.getThumbnailSuccess(response.data));
//       dispatch(slice.actions.setLoading(false));
//     } catch (error: any) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function getPhotos (eventId: number, original?: boolean) {
  return async () => {
    try {
      dispatch(slice.actions.setLoading(true));
      const url = !!original ? `${API_HOST}/photo/${eventId}?type=original` : `${API_HOST}/photo/${eventId}`
      const response = await axios.get(url);
      if (!!original) {
        dispatch(slice.actions.getPhotoSuccess(response.data));
      } else {
        dispatch(slice.actions.getThumbnailSuccess(response.data));
      }
      dispatch(slice.actions.setLoading(false));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deletePhoto (eventId: string, name: string) {
  return async () => {
    try {
      dispatch(slice.actions.setLoading(true));
      await axios.delete(`${API_HOST}/photo/${eventId}`, {
        params: {
          name
        }
      });
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
